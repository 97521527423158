//
// Override global variables
//

// Theme colors
$primary: #3699ff;
$primary-hover: #187de4;
$primary-light: #c9f7f5;
$primary-inverse: #ffffff;

$info: #6993ff;
$info-light: #e1e9ff;
$info-hover: #4a7dff;
$info-inverse: #ffffff;

$success: #3699ff;
$success-hover: #187de4;
$success-light: #e1f0ff;
$success-inverse: #ffffff;

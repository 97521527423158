//
// leaflet
//

.leaflet-container {
  .leaflet-pane,
  .leaflet-top,
  .leaflet-bottom,
  .leaflet-control {
    z-index: 1 !important;
  }

  .leaflet-popup-content-wrapper {
    border-radius: $border-radius !important;
    text-align: center;
    box-shadow: $box-shadow !important;

    .leaflet-popup-content {
      font-family: $font-family-base;
      font-size: $font-size-base;
    }
  }

  height: 100%;
  position: absolute;
  left: 100px;
  right: 0;
  top: 0;
}

// タイルの枠線
// .leaflet-tile {
//   border: solid black 1px;
// }
